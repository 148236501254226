import React from "react";
import { logout } from "../../actions/authAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  UserCircleIcon,
  InboxIcon,
  PowerIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Profile_sidebar = ({ logout, user_type, user }) => {
  const { t } = useTranslation();

  const logOut = () => {
    logout();
    // logout({ last_login_id: user.last_login_id });
  };
  return (
    <Card className="h-[100vh] p-4 pt-20 mt-2 border-none outline-none shadow-none">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          {t("lang.sidebar2.text1")}
        </Typography>
      </div>
      <List>
        <Link to="/profile" className="mb-2">
          <ListItem className="mb-2 bg-gray-200 hover:bg-gray-400">
            <ListItemPrefix>
              <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
            <div >{t("lang.sidebar2.button1")}</div>
          </ListItem>
        </Link>
        {user_type === 2 ? (
          <Link to="/recycle" className="mb-2">
            <ListItem className="mb-2 bg-gray-200 hover:bg-gray-400">
              <ListItemPrefix>
                {/* <FontAwesomeIcon icon={faTrash} className="h-5 w-5"/> */}
                {/* <PresentationChartBarIcon className="h-5 w-5" /> */}
                <TrashIcon className="h-5 w-5" />
              </ListItemPrefix>
              <div >{t("lang.sidebar2.button2")}</div>
            </ListItem>
          </Link>
        ) : (
          ""
        )}

        <ListItem
          onClick={logOut}
          className="mb-2 bg-gray-200 hover:bg-gray-400"
        >
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          <div >{t("lang.sidebar2.button3")}</div>
        </ListItem>
      </List>
    </Card>
  );
};

Profile_sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  // isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
});

export default connect(mapStateToProps, { logout })(Profile_sidebar);
// export default Profile_sidebar
