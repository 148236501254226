import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Typography,
  Button,
  ButtonGroup,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  CardHeader,
  Chip,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import {
  PencilIcon,
  ArchiveBoxIcon,
  PlayIcon,
  StopIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";

import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import {
  createAuto,
  createManual,
  initEmailList,
  changeStatus,
  deleteEmail,
  editEmailFunc,
  generatesWithAi,
} from "../../actions/tempAction";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

import { Select, Option } from "@material-tailwind/react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Sidebar from "../layout/Sidebar";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Header from "../layout/Header";
import Foot_but from "../layout/Foot_but";
import Spinner from "../layout/Spinner";

// Inside the component
const Temporary = ({
  user,
  emailList,
  createAuto,
  createManual,
  initEmailList,
  changeStatus,
  deleteEmail,
  loading,
  editEmailFunc,
  generatesWithAi,
  logout,
}) => {
  const { t } = useTranslation();
  const isIdle = useIdleDetection(600000); // 10 minutes

  const TABLE_HEAD = [
    t("lang.TABLE_HEAD.No"),
    t("lang.TABLE_HEAD.Temporary Email"),
    t("lang.TABLE_HEAD.Password"),
    // t("lang.TABLE_HEAD.Created Date"),
    t("lang.TABLE_HEAD.Status"),
    t("lang.TABLE_HEAD.Change Status"),
    t("lang.TABLE_HEAD.Delete"),
  ];

  const [search, setSearch] = React.useState("");
  const [pageSize, setPageSize] = React.useState(5);
  const [firstIndex, setFirstIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(4);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const emailListData = emailList;
  let getData = [];
  let searchData = [];

  if (isIdle) {
    logout();
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePrevious = () => {
    if (firstIndex > 0) {
      setFirstIndex(firstIndex - pageSize);
      setLastIndex(lastIndex - pageSize);
      setPageIndex(pageIndex - pageSize);
    }
  };

  const handleNext = () => {
    if (lastIndex < emailListData.length) {
      setFirstIndex(firstIndex + pageSize);
      setLastIndex(lastIndex + pageSize);
      setPageIndex(pageIndex + pageSize);
    }
  };

  const handlePageSize = (value) => {
    setPageSize(value);
  };

  useEffect(() => {
    initEmailList({ id: user.id, value: 2 });
    setLastIndex(pageSize - 1);
  }, []);

  let countData = 0;
  for (let i = 0; i < emailListData.length; i++) {
    if (
      emailListData[i] &&
      emailListData[i].temp_email
        .toLowerCase()
        .includes(search.toLowerCase()) &&
      (emailListData[i].email_status == null ||
        emailListData[i].email_status.id == 1 ||
        emailListData[i].email_status.id == 2 ||
        emailListData[i].email_status.status == "active" ||
        (emailListData[i].email_status.status == "inactive" &&
          emailListData[i].deleted_at == null))
    ) {
      searchData.push(emailListData[i]);
      countData += 1;
    }
  }

  const get_table_data = (firstIndex, lastIndex) => {
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (searchData[i]) {
        getData.push(searchData[i]);
      }
    }
  };

  get_table_data(firstIndex, lastIndex);

  const TABLE_ROWS = getData.map(
    (
      {
        id,
        temp_email,
        temp_password,
        email_status,
        route_id,
        user_id,
        email_lock_status,
      },
      index
    ) => {
      return {
        id,
        temp_password,
        route_id,
        user_id,
        status: email_status,
        no: pageIndex + index + 1,
        temp_email,
        lock_status: email_lock_status ? email_lock_status : "unknown",
      };
    }
  );

  const [openManual, setOpenManual] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [edt_email, setEdtEmail] = React.useState("");
  const [edt_password, setEdtPassword] = React.useState("");
  const [edit_id, setId] = React.useState("");
  const [domain, setDomain] = React.useState("@egener.pw");

  const handleDomainChange = (e) => {
    setDomain(e);
  };

  const handleOpenManual = () => setOpenManual((cur) => !cur);

  const createTempAuto = () => {
    const { id: user_id, user_type } = user;
    createAuto({ user_id, user_type });
  };
  const generatesWithAiTemp = () => {
    const { id: user_id, user_type } = user;
    generatesWithAi({ user_id, user_type });
  };

  const [inputData, setInputData] = useState({
    temp_email: "",
    password: "",
  });
  const { temp_email, password } = inputData;
  const onChangeInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value.replace(/\s/g, '') });
  };

  const onSubmitManual = (e) => {
    e.preventDefault();
    if (temp_email != "")
      createManual({
        user_id: user.id,
        temp_email: temp_email + domain,
        password,
        user_type: user.user_type,
      })
        .then((res) => setInputData({ temp_email: "", password: "" }))
        .catch((err) => console.log(err));
    setOpenManual(false);
  };

  function generatePassword() {
    const regex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/;
    const allowedChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@$!%*?&";

    let password = "";
    do {
      password = "";
      for (let i = 0; i < 12; i++) {
        // You can adjust the length of the password as needed
        const randomIndex = Math.floor(Math.random() * allowedChars.length);
        password += allowedChars[randomIndex];
      }
    } while (!regex.test(password));

    return password;
  }

  // // Example usage:
  // const generatedPassword = generatePassword();
  // generatedPassword);

  // Function to handle password generation
  const handleGenerate = () => {
    // Generate a password and update the state
    let pwd = generatePassword();
    setInputData({ ...inputData, password: pwd });
  };

  const handleChangeStatus = (
    temp_email_id,
    temp_email,
    origin,
    target,
    id
  ) => {
    changeStatus({
      temp_email_id,
      temp_email,
      origin,
      target,
      id,
      user_type: user.user_type,
    });
  };

  const handleDeleteEmail = (temp_email_id, origin) => {
    deleteEmail({ temp_email_id, origin, user });
  };

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Sidebar
        createTempAuto={createTempAuto}
        handleOpenManual={handleOpenManual}
        generatesWithAiTemp={generatesWithAiTemp}
      />
      <Divider orientation="vertical" flexItem />
      <div className="w-full flex flex-col ">
        <Dialog
          size="xs"
          open={openManual}
          handler={handleOpenManual}
          className="bg-transparent shadow-none"
        >
          <Card>
            <form onSubmit={onSubmitManual}>
              <Typography
                variant="h5"
                className="flex justify-center mt-4 font-serif"
              >
                {t("lang.manual.head")}
              </Typography>
              <CardBody className="flex flex-col gap-4">
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    label={t("lang.manual.input1")}
                    variant="standard"
                    name="temp_email"
                    value={temp_email}
                    onChange={onChangeInput}
                    required
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <div>
                    <Select
                      label={t("lang.manual.input2")}
                      value={domain}
                      onChange={handleDomainChange}
                      required
                    >
                      <Option value="@egener.pw">@egener.pw</Option>
                      <Option value="@ofermia.pw">@ofermia.pw</Option>
                      <Option value="@offermia.pw">@offermia.pw</Option>
                      <Option value="@etmp.egener.es">@etmp.egener.es</Option>
                    </Select>
                  </div>
                </Paper>
                <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("lang.manual.input3")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={onChangeInput}
                    endAdornment={
                      <InputAdornment position="end" className="space-x-2">
                        <Button
                          variant="text"
                          className="bg-black text-white hover:bg-white hover:text-black border border-black"
                          onClick={handleGenerate}
                        >
                          {t("lang.manual.input4")}
                        </Button>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className="bg-black"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </CardBody>
              <CardFooter className="pt-0 flex flex-row gap-2">
                <Button
                  type="button"
                  className="bg-black"
                  onClick={handleOpenManual}
                  fullWidth
                >
                  {t("lang.manual.button2")}
                </Button>
                <Button type="submit" className="bg-black" fullWidth>
                  {t("lang.manual.button1")}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </Dialog>
        <Card className="shadow-none">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none m-0"
          >
            <div className="flex flex-col justify-between md:flex-row md:items-center mb-6 p-4">
              <div>
                {t("lang.free.text1")}: <b>{countData}</b>
              </div>
              <div className="w-full md:w-72">
                <Input
                  id="search"
                  onChange={handleSearch}
                  label={t("lang.free.text2")}
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                />
              </div>
            </div>
          </CardHeader>

          {emailListData.length === 0 ? (
            <div className="flex justify-center text-xl font-semibold">
              {" "}
              {t("lang.free.text3")}{" "}
            </div>
          ) : (
            <CardBody className="px-0">
              <div className="overflow-auto h-[50dvh]">
                {loading ? (
                  <div className="w-full flex justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <table className="w-full pb-6 text-center">
                    <thead>
                      <tr className="border-y border-black-gray-100 bg-black-gray-50">
                        {TABLE_HEAD.map((head) => (
                          <th key={head} className="p-4">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {TABLE_ROWS.map(
                        (
                          {
                            id,
                            temp_password,
                            route_id,
                            user_id,
                            status,
                            no,
                            temp_email,
                            lock_status,
                          },
                          index
                        ) => {
                          const isLast = index === TABLE_ROWS.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";
                          return (
                            <tr key={id}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold"
                                  >
                                    {no}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {temp_email}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {temp_password}
                                </Typography>
                              </td>

                              <td className={classes}>
                                {lock_status.lock_status === "Locked" ? (
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    value={lock_status.lock_status}
                                    color={
                                      lock_status.lock_status === "Locked"
                                        ? "red"
                                        : "blue"
                                    }
                                  />
                                ) : (
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    value={status.status}
                                    color={
                                      status.status === "inactive"
                                        ? "yellow"
                                        : "green"
                                    }
                                  />
                                )}
                              </td>
                              {lock_status.lock_status === "Locked" ? (
                                <td className={classes}>
                                  <Tooltip content="Email locked">
                                    <IconButton
                                      variant="text"
                                      color="blue-gray"
                                    >
                                      <LockClosedIcon className="h-4 w-4" />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              ) : status.status == "inactive" ? (
                                <td className={classes}>
                                  <Tooltip content="Change to active">
                                    <IconButton
                                      variant="text"
                                      color="blue-gray"
                                      onClick={() =>
                                        handleChangeStatus(
                                          id,
                                          temp_email,
                                          "inactive",
                                          "active",
                                          user.id
                                        )
                                      }
                                    >
                                      <PlayIcon className="h-4 w-4" />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              ) : (
                                <td className={classes}>
                                  <Tooltip content="Change to inactive">
                                    <IconButton
                                      variant="text"
                                      color="blue-gray"
                                      onClick={() =>
                                        handleChangeStatus(
                                          id,
                                          temp_email,
                                          "active",
                                          "inactive",
                                          user.id
                                        )
                                      }
                                    >
                                      <StopIcon className="h-4 w-4" />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              )}

                              <td className={classes}>
                                <Tooltip content="Delete Email">
                                  <IconButton
                                    variant="text"
                                    color="blue-gray"
                                    onClick={() =>
                                      handleDeleteEmail(id, "deleted")
                                    }
                                  >
                                    <ArchiveBoxIcon className="h-4 w-4" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </CardBody>
          )}
        </Card>
        <Foot_but user={user} />
      </div>
    </div>
  );
};

Temporary.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  createAuto: PropTypes.func.isRequired,
  generatesWithAi: PropTypes.func.isRequired,
  createManual: PropTypes.func.isRequired,
  initEmailList: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  emailList: state.tempReducer.temporary_email,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  createAuto,
  createManual,
  initEmailList,
  changeStatus,
  deleteEmail,
  editEmailFunc,
  generatesWithAi,
  logout,
})(Temporary);
