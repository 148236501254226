import React from "react";
import { useEffect } from "react";
import { Suspense } from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import { Auth } from "./components/auth/Auth";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import NotFound from "./components/layout/NotFound";
import AlertComponent from "./components/layout/AlertComponent";

import Temporary from "./components/temporary/Temporary";
import Profile from "./components/profile/Profile";
import UserManage from "./components/user_mng/UserManage";
import Incidents from "./components/Incidents/Incidents";
import Temporary_admin from "./components/temporary_admin/Temporary_admin";
import LastLogin from "./components/lastLogins/LastLogin";

import LoadRoute from "./components/routing/LoadRoute";
import AuthRoute from "./components/routing/AuthRoute";

import setAuthToken from "./utils/setAuthToken";
import setUserId from "./utils/setUserId";

import { LOG_OUT } from "./actions/types";

import Recycle from "./components/recycle/Recycle";
import Signup from "./components/auth/signUp/Signup";

import Home from "./components/Home/Home";

import { loadUser } from "./actions/authAction";

const App = () => {

  useEffect(() => {
    // check for token in LS when app first runs
    if (sessionStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(sessionStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API

    if (sessionStorage.user) {
      setUserId(sessionStorage.user);
      store.dispatch(loadUser(sessionStorage.user));
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!sessionStorage.token) store.dispatch({ type: LOG_OUT });
    });
  }, []);
  return (
    <Provider store={store}>
      {/* <Suspense fallback="loading"> */}
      <BrowserRouter>
        <AlertComponent />


        <Routes>

          <Route path="/" element={<LoadRoute component={Home} />} />
          <Route path="/login" element={<LoadRoute component={Login} />} />

          <Route path="/register" element={<LoadRoute component={Signup} />} />

          <Route
            path="reset_password/:email/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/temporary"
            element={<AuthRoute component={Temporary} />}
          />
          <Route path="/recycle" element={<AuthRoute component={Recycle} />} />
          <Route
            path="/incidents"
            element={<AuthRoute component={Incidents} />}
          />
          <Route
            path="/lastLogin"
            element={<AuthRoute component={LastLogin} />}
          />
          <Route path="/profile" element={<AuthRoute component={Profile} />} />
          <Route
            path="/user_mng"
            element={<AuthRoute component={UserManage} />}
          />
          <Route
            path="/temporary_admin"
            element={<AuthRoute component={Temporary_admin} />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>

      </BrowserRouter>
      {/* </Suspense> */}
    </Provider>
  );
};

export default App;
