import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
} from "@material-tailwind/react";

import {
  TrashIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/solid";

import { contactUs } from "../../actions/userAction";

const Foot_but = ({ isAuthenticated, user, contactUs }) => {
  const email = user.email;
  const user_id = user.id;
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [dis, setDis] = useState(true);
  const { t } = useTranslation();
  const handleOpen = () => setOpen(!open);

  const handleSendMessage = () => {
    const formData = {
      email,
      user_id,
      subject,
      message,
    };
    contactUs(formData).then((res) => {
      setOpen(false);
      setSubject("");
      setMessage("");
    });
  };

  useEffect(() => {
    if (subject && message) setDis(false);
    if (subject == "" || message == "") setDis(true);
  }, [subject, message]);

  return (
    <div className="w-full flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-evenly flex-1">
      <a
        href="https://www.egener.es/ayuda/"
        target="_blank"
        className="w-full px-4"
      >
        <Button className="flex bg-gray-200 text-black hover:shadow-gray focus:shadow-black focus:outline-none w-full md:w-auto justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000000"
            className="w-6 h-6 mr-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
            />
          </svg>
          {t("lang.footerB.button1")}
        </Button>
      </a>
      <Link to="/recycle" className="w-full px-4">
        <Button className="bg-gray-200 text-black hover:shadow-gray focus:shadow-black focus:outline-none flex w-full md:w-auto justify-center">
          <TrashIcon className="h-5 w-5 mr-5" />
          {t("lang.footerB.button2")}
        </Button>
      </Link>
      <a href="#buttons-with-link" className="w-full px-4">
        <Button
          onClick={handleOpen}
          className="bg-gray-200 text-black hover:shadow-gray focus:shadow-black focus:outline-none flex w-full md:w-auto justify-center"
        >
          <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-5" />
          {t("lang.footerB.button3")}
        </Button>
        <Dialog open={open} size="xs" handler={handleOpen}>
          <DialogHeader className="flex justify-between items-center">
            <div className="flex flex-col space-y-3">
              <Typography variant="h4">{t("lang.contact.text1")}</Typography>
              <Typography color="gray" variant="lead">
                {t("lang.contact.text2")}
              </Typography>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5"
              onClick={handleOpen}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </DialogHeader>

          <DialogBody className="py-0">
            <div className="flex flex-col space-y-4">
              <Input
                label={t("lang.contact.text3")}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <Textarea
                label={t("lang.contact.text4")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button variant="text" onClick={handleOpen} className="text-black">
              {t("lang.contact.button1")}
            </Button>
            <Button
              disabled={dis}
              className="bg-gradient-to-tr to-black from-black disabled:bg-black/75"
              variant="gradient"
              onClick={handleSendMessage}
            >
              {t("lang.contact.button2")}
            </Button>
          </DialogFooter>
        </Dialog>
        {/* <Button className="w-80 bg-gray-200 text-black hover:shadow-black focus:shadow-black focus:outline-none">
            {t("lang.footerB.button3")}
          </Button> */}
      </a>
    </div>
  );
};

Foot_but.propTypes = {
  user: PropTypes.object,
  contactUs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { contactUs })(Foot_but);
